<template>
  <v-form class="form" v-model="valid" @submit.prevent>
    <h2>{{ $t('ticketing.payment.title') }}</h2>

    <v-stripe-card
      v-model="paymentForm.card"
      :api-key="apiKey"
      :locale="locale"
      font-name="Poppins"
      :rules="rules.card"
      :custom-style="style"
      required
    />
    <v-text-field
      color="primary"
      :label="$t('ticketing.payment.name')"
      v-model="paymentForm.name"
      :rules="rules.name"
      required
    />
    <v-text-field
      id="autocomplete"
      color="primary"
      placeholder
      :label="$t('ticketing.payment.address')"
      v-model="paymentForm.fullAddress"
      :rules="rules.address"
      required
    />

    <div class="error--text" v-if="errorMessage">
      <div v-if="errorType === 'stripe'">
        {{ $t('ticketing.payment.stripeError') }}
      </div>
      <div v-else>
        {{ $t('ticketing.payment.unexpectedError') }}
      </div>
      <br />
      {{ errorMessage }}
    </div>

    <div class="actions mt-4 mb-16">
      <v-btn
        class="secondary-btn"
        color="secondary"
        outlined
        tile
        x-large
        :disabled="!valid"
        :loading="isLoading"
        @click.native="$emit('pay', paymentForm)"
        >{{ $t('ticketing.payment.pay') }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import '@/stripe/stripe-loader';
import VStripeCard from 'v-stripe-elements/lib/VStripeCard';
import stripeOptions from '@/stripe/stripe-options';
import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'Payment',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    errorType: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    VStripeCard,
  },
  data: () => ({
    autocomplete: null,
    apiKey: stripeOptions.apiKey,
    style: stripeOptions.style,
    locale: stripeOptions.locale,
    paymentForm: {
      card: '',
      name: '',
      fullAddress: '',
      address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
    },
    rules: {
      card: VALIDATORS.TICKETING.CARD,
      name: VALIDATORS.TICKETING.NAME,
      address: VALIDATORS.TICKETING.ADDRESS,
    },
    valid: false,
  }),
  methods: {
    initAutocomplete() {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        {
          types: ['geocode'],
          componentRestrictions: { country: 'ca' },
        },
      );
      this.autocomplete.setFields(['address_component', 'formatted_address']);
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        const structuredPlace = {
          formattedAddress: place.formatted_address,
        };
        place.address_components.forEach((component) => {
          const addressType = component.types[0];
          structuredPlace[addressType] = {
            longName: component.long_name,
            shortName: component.short_name,
          };
        });
      });
    },
  },
  mounted() {
    this.initAutocomplete();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.form {
  width: 100%;
}

@include breakpoint(medium) {
  .form {
    width: 50%;
  }
}

.actions {
  display: flex;
  justify-content: center;
}
</style>
